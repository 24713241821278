const flash = (type, message) => {
  const element = document.createElement('div')
  element.dataset.controller = 'flash'
  element.dataset.flashType = type
  element.dataset.flashMessage = message
  document.body.appendChild(element)
}

const flashAlert = message => {
  flash('alert', message)
}

const flashError = message => {
  flash('error', message)
}

const flashInfo = message => {
  flash('info', message)
}

const flashNotice = message => {
  flash('notice', message)
}

const flashSuccess = message => {
  flash('success', message)
}

const flashWarning = message => {
  flash('warning', message)
}

export { flash, flashAlert, flashError, flashInfo, flashNotice, flashSuccess, flashWarning }
