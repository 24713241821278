import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['canceled', 'cancelFields', 'notice']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.toggleShowCancelFields(this.canceledTarget, true)
  }

  /* ----- ACTION EVENTS ----- */

  onCanceledChange(event) {
    this.toggleShowCancelFields(event.currentTarget, false)
  }

  /* ----- FUNCTIONS ----- */
  toggleShowCancelFields(canceled, initial) {
    if(canceled.checked) {
      this.cancelFieldsTarget.classList.remove('hide')
      if(!initial) {
        this.noticeTarget.classList.remove('hide')
      }
    } else {
      this.cancelFieldsTarget.classList.add('hide')
      if(!initial) {
        this.noticeTarget.classList.add('hide')
      }
    }
  }

  /* ----- GETTERS / SETTERS ----- */
}
