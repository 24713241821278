import { Controller } from 'stimulus'

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.toggleSidebarCollapse()
  }

  /* ----- ACTION EVENTS ----- */

  onToggleSidebarClick(event) {
    this.toggleSidebarCollapse()
    this.sidebarCollapseState = !this.sidebarCollapseState
  }

  /* ----- FUNCTIONS ----- */

  toggleSidebarCollapse() {
    document.body.classList.toggle('sidebar-collapse', this.sidebarCollapseState)
  }

  /* ----- GETTERS / SETTERS ----- */

  get sidebarCollapseState() {
    return JSON.parse(sessionStorage.getItem('sidebar-collapse'))
  }

  set sidebarCollapseState(state) {
    sessionStorage.setItem('sidebar-collapse', state)
  }
}
