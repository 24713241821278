import { Controller } from 'stimulus'
import { get } from 'scripts/utils/ajax'

export default class extends Controller {
  static targets = ['contractVersion', 'contractVersionCommissionLevel']

  /* ----- ACTION EVENTS ----- */

  onContractVersionChange(event) {
    if (event.currentTarget.value == '') {
      this.contractVersionCommissionLevelTarget.innerHTML = ''
      return
    }

    const source = event.currentTarget.dataset.indexSource + '/' + event.currentTarget.value

    get(source, 'json').then((contractVersion) => {
      this.contractVersionCommissionLevelTarget.innerHTML = ''

      contractVersion.commission_levels.forEach((level) => {
        let option = document.createElement('option')
        option.setAttribute('value', level.id)
        option.innerHTML = level.text
        this.contractVersionCommissionLevelTarget.appendChild(option)
      })
    })
  }
}
