import { Controller } from 'stimulus'
import { useControllerMixin } from 'scripts/mixins/controller'

export default class extends Controller {
  static targets = ['cancellationRequestedOn', 'noticeEndedOn', 'noticeDurationInMonth']

  /* ----- LIFECYCLE CALLBACKS ----- */
  initialize() {
    useControllerMixin(this)
  }

  /* ----- ACTION EVENTS ----- */

  onCancellationRequestedOnChange(event) {
    if(this.requestedOn != event.currentTarget.value) {
      this.setNoticeEndedOnValue(event.currentTarget)
    }

    this.requestedOn = event.currentTarget.value
  }

  /* ----- FUNCTIONS ----- */

  setNoticeEndedOnValue(cancellationRequestedOn) {
    const cancellationRequestedOnInDate = new Date(cancellationRequestedOn.value)
    const noticeDurationInMonth = parseInt(this.noticeDurationInMonthTarget.innerHTML)
    const noticeEndedOn = new Date(cancellationRequestedOnInDate.setMonth(cancellationRequestedOnInDate.getMonth() + noticeDurationInMonth))

    const pickerControllerElement = this.noticeEndedOnTarget.closest('[data-controller="inputs--datetime-picker"]')
    const pickerController = this.getControllerForElement(pickerControllerElement, 'inputs--datetime-picker')
    pickerController.picker.setDate(noticeEndedOn, false)
  }

  /* ----- GETTERS / SETTERS ----- */
}
