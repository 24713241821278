import { Controller } from 'stimulus'
import Chart from 'chart.js/auto';

export default class extends Controller {
  static targets = [ 'chart', 'selector']
  static values = { type: String }

  /* ----- LIFECYCLE CALLBACKS ----- */
  initialize() {
    this.chartInstance = null
  }

  connect() {
    this.initGraph()
  }

  /* ----- FUNCTIONS ----- */

  initGraph() {
    let ctx = this.chartTarget;
    if (this.typeValue === 'bar') {
      this.chartInstance = new Chart(ctx, {
        type: 'bar',
        data: {},
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });
    } else {
      this.chartInstance = new Chart(ctx, {
        type: 'line',
        data: {
          labels: ['Septembre', 'Octobre', 'Novembre', 'Décembre', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout'],
          datasets: [
            {
              label: '20122-21',
              data: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200],
              borderColor: '#386641',
              backgroundColor: '#386641',
            },
            {
              label: '2019-20',
              data: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200],
              borderColor: '#6a994e',
              backgroundColor: '#6a994e',
            },
            {
              label: '2018-19',
              data: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200],
              borderColor: '#a7c957',
              backgroundColor: '#a7c957',
            },
          ]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });
    }
    this.clearAndAddNewDataSets()
  }

  clearAndAddNewDataSets() {
    this.chartInstance.config.data.datasets = []
    this.chartInstance.config.data.labels = []

    const datasetOptions = []
    const datasetObj = {}

    const labels = JSON.parse(this.selectorTarget.dataset.labels)
    const xLabels = JSON.parse(this.selectorTarget.dataset.xlabels)

    const selectedOption = this.selectorTarget.options[this.selectorTarget.selectedIndex]
    const bgColors = JSON.parse(selectedOption.dataset.bgColors)
    const borderColors = JSON.parse(selectedOption.dataset.borderColors)
    const datas = JSON.parse(selectedOption.dataset.datas)

    datasetObj.labels = labels
    datasetObj.datas = datas
    datasetObj.xLabels = xLabels
    datasetObj.borderColors = borderColors
    datasetObj.backgroundColors = bgColors

    datasetOptions.push(datasetObj)

    let _this = this

    Array.prototype.forEach.call(datasetOptions, function(el, index){
      let labels = el.labels;
      let data = el.datas
      let xLabels = el.xLabels
      let borderColor = el.borderColors
      let backgroundColor = el.backgroundColors

      _this.chartInstance.config.data.labels = labels

      let i
      for (i = 0; i < data.length; i++) {
          let dataSet = {
          label: xLabels[i],
          data: data[i],
          borderColor: borderColor[i],
          backgroundColor: backgroundColor[i]
        }

        _this.chartInstance.config.data.datasets.push(dataSet)
      }
      _this.chartInstance.update()
    });
  }
}
