import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['link', 'template', 'destination']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.wrapperClass = this.data.get('wrapperClass') || 'nested-fields'
  }

  /* ----- ACTION EVENTS ----- */

  onAddAssociationClick(event) {
    event.preventDefault()
    this.addAssociation(event.currentTarget)
  }

  onRemoveAssociationClick(event) {
    event.preventDefault()
    this.removeAssociation(event.currentTarget)
  }

  /* ----- FUNCTIONS ----- */

  addAssociation(target) {
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())

    if (this.destinationTarget.dataset.insertMethod == 'prepend') {
      this.destinationTarget.insertAdjacentHTML('afterbegin', content)
    } else {
      this.destinationTarget.insertAdjacentHTML('beforeend', content)
    }

    const customEvent = new Event('association-added')
    this.element.dispatchEvent(customEvent)
  }

  removeAssociation(target) {
    const wrapper = target.closest('.' + this.wrapperClass)

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.classList.add('d-none')
    }

    const customEvent = new Event('association-removed')
    this.element.dispatchEvent(customEvent)
  }

  /* ----- GETTERS / SETTERS ----- */
}
