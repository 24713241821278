import { start as ujs } from '@rails/ujs'
import { start as turbolinks } from 'turbolinks'
import { start as activestorage } from '@rails/activestorage'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import { RemoteController } from 'stimulus-remote'
import Confirm from 'scripts/utils/confirm'
import 'orgchart/dist/js/jquery.orgchart.min'
import 'scripts/channels'

import 'stylesheets/application/style'

Confirm.init() // Confirm init always before Rails start

ujs()
turbolinks()
activestorage()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('images', true)
const imagePath = (name) => images(name, true)

const application = Application.start()
const context = require.context('scripts/application/controllers', true, /\.js$/)

// TODO : remove if not necessary
// Add controller event to fix turbolinks cache
// document.addEventListener('turbolinks:before-cache', () => {
//   application.controllers.forEach(controller => {
//     if (typeof controller.teardown === 'function') {
//       controller.teardown()
//     }
//   })
// })

application.register('remote', RemoteController)
application.load(definitionsFromContext(context))
