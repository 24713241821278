import tippy from 'tippy.js'
import { Controller } from 'stimulus'

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.setup()
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  setup() {
    this.tooltip = tippy(`[data-controller*="${this.identifier}"]`, this.options)
  }

  /* ----- GETTERS / SETTERS ----- */

  get options() {
    return {}
  }
}
