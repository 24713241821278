import { Controller } from 'stimulus'
import { get } from 'scripts/utils/ajax'

export default class extends Controller {
  static targets = ['begunOn', 'contractualisationFields', 'contractVersion', 'contractVersionCommissionLevel']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.toggleShowContractualisationFields(this.begunOnTarget)
  }

  /* ----- ACTION EVENTS ----- */

  onBegunOnChange(event) {
    if(this.begunOn != event.currentTarget.value) {
      this.toggleShowContractualisationFields(event.currentTarget)
      this.updateContractVersion(event.currentTarget)
    }

    this.begunOn = event.currentTarget.value
  }

  onContractVersionChange(event) {
    if (event.currentTarget.value == '') {
      this.contractVersionCommissionLevelTarget.innerHTML = ''
      return
    }

    const source = event.currentTarget.dataset.indexSource + '/' + event.currentTarget.value

    get(source, 'json').then((contractVersion) => {
      this.contractVersionCommissionLevelTarget.innerHTML = ''

      contractVersion.commission_levels.forEach((level) => {
        let option = document.createElement('option')
        option.setAttribute('value', level.id)
        option.innerHTML = level.text
        this.contractVersionCommissionLevelTarget.appendChild(option)
      })
    })
  }

  /* ----- FUNCTIONS ----- */
  toggleShowContractualisationFields(begunOn) {
    if (this.hasContractualisationFieldsTarget) {
      if (begunOn.value == undefined || begunOn.value == '') {
        this.contractualisationFieldsTarget.classList.add('hide')
      } else {
        this.contractualisationFieldsTarget.classList.remove('hide')
      }
    }
  }

  updateContractVersion(begunOn) {
    let source = new URL(this.contractVersionTarget.dataset.autocompleteSource)
    source.searchParams.set('last_contract_version_of_contract_available_on', begunOn.value)

    get(source, 'json').then((contractVersions) => {
      this.contractVersionTarget.innerHTML = ''

      contractVersions.forEach((contractVersion) => {
        let option = document.createElement('option')
        option.setAttribute('value', contractVersion.id)
        option.innerHTML = contractVersion.text
        this.contractVersionTarget.appendChild(option)
      })

      const event = new Event('change')
      this.contractVersionTarget.dispatchEvent(event)
    })
  }
}
