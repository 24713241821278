import { Controller } from 'stimulus'
import { useControllerMixin } from 'scripts/mixins/controller'
import { get } from 'scripts/utils/ajax'

export default class extends Controller {
  static targets = ['amount', 'penaltiesAmount', 'creditNoteAmount', 'dueAmount', 'arrearsAmount']

  /* ----- LIFECYCLE CALLBACKS ----- */
  connect() {
    if (this.hasAmountTarget) {
      // this.updateDueAmount()
    }
  }

  /* ----- ACTION EVENTS ----- */

  onYearChange(event) {
    event.currentTarget.closest('form').submit()
  }

  onAmountOnChange(event) {
    // this.updateDueAmount()
  }

  /* ----- FUNCTIONS ----- */

  updateDueAmount() {
    let dueAmount = (this.amountTarget.value ? parseInt(this.amountTarget.value) : 0) + (this.penaltiesAmountTarget.value ? parseInt(this.penaltiesAmountTarget.value) : 0) - (this.creditNoteAmountTarget.value ? parseInt(this.creditNoteAmountTarget.value) : 0) + (this.arrearsAmountTarget.dataset.value ? parseInt(this.arrearsAmountTarget.dataset.value) : 0)
    this.dueAmountTarget.textContent = dueAmount + ' € HT'
  }
}
