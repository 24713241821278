import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'inputName', 'path']

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    this.arrayName = []
  }

  connect() {
    this.toggleClassAndToggleDataNameOnLoad()
  }

  /* ----- ACTION EVENTS ----- */

  toggleClassPath(e) {
    if (e.currentTarget.classList.contains('is-selected')) {
      e.currentTarget.classList.remove('is-selected')
    } else {
      e.currentTarget.classList.add('is-selected')
    }
  }

  toggleDataNameIntoInputOnClick(e) {
    let _this = this
    const name = e.currentTarget.dataset.name
    if (e.currentTarget.classList.contains('is-selected')) {
      _this.arrayName.push(name)
      _this.sendArrayToInputValueHidden()
    } else {
      _this.removeDataNameFromArray(name)
      _this.sendArrayToInputValueHidden()
    }
  }

  /* ----- FUNCTIONS ----- */

  removeDataNameFromArray(data) {
    this.arrayName.splice(this.arrayName.indexOf(data), 1)
  }

  sendArrayToInputValueHidden() {
    this.inputNameTarget.value = this.arrayName
  }

  toggleClassAndToggleDataNameOnLoad() {
    const inputValue = this.inputNameTarget.value
    const regions = inputValue.split(',')
    this.pathTargets.forEach((path) => {
      if (regions.includes(path.dataset.name)) {
        path.classList.add('is-selected')
        this.arrayName.push(path.dataset.name)
      }
    })
  }

  /* ----- GETTERS / SETTERS ----- */
}
