import 'select2'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['select']

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    this.modalParent = !!this.element.closest('.modal-body')
  }

  connect() {
    this.setup()
  }

  /* ----- ACTION EVENTS ----- */

  onOpenFocusin(event) {
    this.open(event.currentTarget)
  }

  /* ----- FUNCTIONS ----- */

  setup() {
    if (!this.element.querySelector('.select2-container')) {
      $(this.selectTarget).select2(this.options)

      if (this.type === 'single') {
        $($(this.selectTarget).data('select2').$container)[0].dataset.action = 'focusin->inputs--select2#onOpenFocusin'
      }

      $(this.selectTarget).on('select2:select', function () {
        let event = new Event('change')
        this.dispatchEvent(event)
      });
    }
  }

  open(target) {
    $(target.parentNode.querySelectorAll('select:enabled')).select2('open')
  }

  reset() {
    $(this.selectTarget).val(null).trigger('change');
  }
  /* ----- GETTERS / SETTERS ----- */

  get type() {
    if (this.selectTarget.hasAttribute('multiple')) {
      return 'multiple'
    } else {
      return 'single'
    }
  }

  get options() {
    let options = this.defaultOptions

    if (this.selectTarget.dataset.source) {
      options = { ...options, ...this.autocompleterOptions }
    }

    if (this.modalParent) {
      options = { ...options, ...this.modalOptions }
    }

    return options
  }

  get defaultOptions() {
    const placeholder = this.selectTarget.querySelector('option[value=""]') ? this.selectTarget.querySelector('option[value=""]').innerHTML : ''

    return {
      width: '100%',
      disabled: this.selectTarget.hasAttribute('readonly'),
      placeholder: placeholder,
      minimumResultsForSearch: 1,
      allowClear: !this.selectTarget.dataset.disallowClear,
      multiple: this.type === 'multiple',
      templateSelection: function(obj) {
        if (obj.element) {
          const selectionLabel = obj.element.dataset.selectionLabel

          if (selectionLabel) {
            obj.selectionLabel = selectionLabel
          }
        }

        return obj.selectionLabel ? $(obj.selectionLabel) : obj.text
      },
      templateResult: function(obj, container) {
        if (obj.element) {
          const resultLabel = obj.element.dataset.resultLabel

          if (resultLabel) {
            obj.resultLabel = resultLabel
          }
        }

        return obj.resultLabel ? $(obj.resultLabel) : $('<span></span>').html(obj.text)
      },
    }
  }

  get autocompleterOptions() {
    return {
      minimumInputLength: 2,
      ajax: {
        url: this.selectTarget.dataset.source,
        quietMillis: 250,
        dataType: 'json',
        data: function (params) {
          return {
            search: params.term,
          }
        },
        processResults: function (data) {
          return {
            results: data
          }
        }
      }
    }
  }

  get modalOptions() {
    return {
      dropdownParent: '.modal-body'
    }
  }
}
