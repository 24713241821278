import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['participant', 'capitalReceivedFromNotary', 'businessFinderType', 'blAgentsParticipantFields', 'blAgentsBusinessFinderTypeFields', 'externParticipantFields']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.toggleShowParticipantFields(this.participantTarget)
    this.toggleShowBusinessFinderTypeFields(this.businessFinderTypeTarget)
  }

  /* ----- ACTION EVENTS ----- */

  onParticipantChange(event) {
    this.toggleShowParticipantFields(event.currentTarget)
  }

  onBusinessFinderTypeChange(event) {
    this.toggleShowBusinessFinderTypeFields(event.currentTarget)
  }

  /* ----- FUNCTIONS ----- */
  toggleShowParticipantFields(participant) {
    const checkedParticipant = participant.closest('.form-group').querySelector('input[type="radio"]:checked')

    if (checkedParticipant) {
      if(checkedParticipant.value == 'extern') {
        this.externParticipantFieldsTarget.classList.remove('hide')
        this.blAgentsParticipantFieldsTarget.classList.add('hide')
      } else {
        this.externParticipantFieldsTarget.classList.add('hide')
        this.blAgentsParticipantFieldsTarget.classList.remove('hide')
      }
    } else {
      this.externParticipantFieldsTarget.classList.add('hide')
      this.blAgentsParticipantFieldsTarget.classList.add('hide')
    }
  }

  toggleShowBusinessFinderTypeFields(businessFinderType) {
    const checkedBusinessFinderType = businessFinderType.closest('.form-group').querySelector('input[type="radio"]:checked')

    if (checkedBusinessFinderType) {
      if(checkedBusinessFinderType.value == 'bl_agents') {
        this.blAgentsBusinessFinderTypeFieldsTarget.classList.remove('hide')
      } else {
        this.blAgentsBusinessFinderTypeFieldsTarget.classList.add('hide')
      }
    } else {
      this.blAgentsBusinessFinderTypeFieldsTarget.classList.add('hide')
    }
  }

  /* ----- GETTERS / SETTERS ----- */
}
