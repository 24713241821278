import Swal from 'sweetalert2'
import { Controller } from 'stimulus'

import { language } from 'scripts/utils/application'
import { FR } from 'scripts/application/locales/confirm/fr'

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    // this.notification = new Notyf(this.options)
  }

  connect() {
    this.open()
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  open() {
    Swal.fire({
      title: this.data.get('title'),
      text: this.data.get('message'),
      confirmButtonColor: '#28a745',
      confirmButtonText: this.locale.confirmButtonText,
    })
  }

  /* ----- GETTERS / SETTERS ----- */

  get locale() {
    return this.locales[language]
  }

  get locales() {
    return {
      fr: FR
    }
  }
}
