import 'datatables.net-bs4'
import { Controller } from 'stimulus'

import { useControllerMixin } from 'scripts/mixins/controller'

export default class extends Controller {
  static targets = ['rowCheckbox', 'batchAction', 'columnCheckbox', 'createButton']

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    useControllerMixin(this)
    this.selectedRowIds = []
  }

  /* ----- ACTION EVENTS ----- */


  onRowCheckboxClick(event) {
    this.toggleRowCheckbox(event.currentTarget)
  }

  onColumnCheckboxClick(event) {
    this.toggleAllRowCheckboxes()
  }

  /* ----- FUNCTIONS ----- */

  toggleAllRowCheckboxes() {
    this.rowCheckboxTargets.forEach(rowCheckbox => {
      rowCheckbox.checked = this.columnCheckboxTarget.checked

      if (rowCheckbox.checked) {
        this.addSelectedRowId(rowCheckbox.value)
      } else {
        this.removeSelectedRowId(rowCheckbox.value)
      }
    })
  }

  toggleRowCheckbox(rowCheckbox) {
    if (rowCheckbox.checked) {
      this.addSelectedRowId(rowCheckbox.value)
    } else {
      this.removeSelectedRowId(rowCheckbox.value)
    }
  }

  addSelectedRowId(id) {
    this.selectedRowIds.push(parseInt(id))
    this.selectedRowIds = [...new Set(this.selectedRowIds)] // Remove duplicates
    this.selectedRowIds.sort() // Sort
    this.setBatchActionIds()
  }

  removeSelectedRowId(id) {
    this.selectedRowIds = this.selectedRowIds.filter(rowId => parseInt(rowId) !== parseInt(id))
    this.setBatchActionIds()
  }

  setBatchActionIds() {
    if (this.currentBatchActionController) {
      this.currentBatchActionController.setSelectionInputValue(this.selectedRowIds)
      this.createButtonTarget.disabled = this.selectedRowIds.length <= 0
    }
  }

  /* ----- GETTERS / SETTERS ----- */

  get currentBatchActionTarget() {
    return [...this.batchActionTargets].pop()
  }

  get currentBatchActionController() {
    return this.currentBatchActionTarget ? this.getControllerForElement(this.currentBatchActionTarget, 'batch-action') : this.currentBatchActionTarget
  }
}
