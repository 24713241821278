import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['kickbacks', 'kickbackSponsoredLevel', 'addKickbackButton', 'registerContributionDiscount', 'registerContributionDiscountFields', 'registerPool', 'registerPoolFields']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.updateSponsoredLevel()
    this.updateAddKickbackButton()
    this.toggleShowRegisterContributionDiscountFields(this.registerContributionDiscountTarget)
    this.toggleShowRegisterPoolFields(this.registerPoolTarget)
  }

  /* ----- ACTION EVENTS ----- */

  onAssociationAddedKickback(event) {
    this.updateSponsoredLevel()
    this.reorderKickbacks()
    this.updateAddKickbackButton()
  }

  onAssociationRemovedKickback(event) {
    this.updateAddKickbackButton()
  }

  onRegisterContributionDiscountChange(event) {
    this.toggleShowRegisterContributionDiscountFields(event.currentTarget)
  }

  onRegisterPoolChange(event) {
    this.toggleShowRegisterPoolFields(event.currentTarget)
  }

  /* ----- FUNCTIONS ----- */
  toggleShowRegisterContributionDiscountFields(registerContributionDiscount) {
    if(registerContributionDiscount.checked) {
      this.registerContributionDiscountFieldsTarget.classList.remove('hide')
    } else {
      this.registerContributionDiscountFieldsTarget.classList.add('hide')
    }
  }

  toggleShowRegisterPoolFields(registerPool) {
    if(registerPool.checked) {
      this.registerPoolFieldsTarget.classList.remove('hide')
    } else {
      this.registerPoolFieldsTarget.classList.add('hide')
    }
  }

  updateSponsoredLevel() {
    const levelValues = this.kickbackLevelValues()
    const [min, max] = [1, Math.max(...levelValues)]

    // Fill potential holes in the kickbacks sequence
    if(isFinite(min) && isFinite(max)) {
      const missingValues = Array.from(Array(max - min), (v, i) => i + min).filter(i => !levelValues.includes(i))

      missingValues.forEach((item, i) => {
        const kickback = this.kickbackSponsoredLevelTargets.filter(l => l.value == '')[0]

        if (kickback) {
          kickback.value = item
        }
      })
    }

    // Add kickbacks to the end of the sequence (maxLevel + 1 or 1)
    this.kickbackSponsoredLevelTargets.filter(level => level.value == '').forEach((level, i) => {
      const maxLevel = Math.max(...this.kickbackLevelValues())
      level.value = isFinite(maxLevel) ? maxLevel + 1 : 1
    })
  }

  reorderKickbacks() {
    [...this.kickbackSponsoredLevelTargets].sort((a,b) => parseInt(a.value) > parseInt(b.value) ? 1 : -1).forEach(node => this.kickbacksTarget.appendChild(node.closest('tr')))
  }

  kickbackLevelValues() {
    return this.kickbackSponsoredLevelTargets.filter(l => {
      const kickbackDestruction = l.closest('tr').querySelector('.kickback-destruction')
      return kickbackDestruction.value != '1' && kickbackDestruction.value != 'true' && l.value != ''
    }).map(l => parseInt(l.value))
  }

  updateAddKickbackButton() {
    const maxLevel = Math.max(...this.kickbackLevelValues())

    if(maxLevel >= this.addKickbackButtonTarget.dataset.maxSponsoredLevel) {
      this.addKickbackButtonTarget.classList.add('hide')
    } else {
      this.addKickbackButtonTarget.classList.remove('hide')
    }
  }

  /* ----- GETTERS / SETTERS ----- */
}
