import { Controller } from 'stimulus'

import { flashNotice } from 'scripts/utils/flash'

export default class extends Controller {
  static values = { message: String }

  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  onClearAjaxSuccess(event) {
    flashNotice(this.messageValue)
  }

  /* ----- FUNCTIONS ----- */

  /* ----- GETTERS / SETTERS ----- */
}
