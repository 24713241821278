import Quill from 'quill'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["editor", "input"]
  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    const toolbar = [
          [{ 'header': [1, 2, false] }],
          [ 'bold', 'italic', 'underline', 'strike' ],
          [{ 'color': [] }, { 'background': [] }],
          ['blockquote', 'code-block' ],
          [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'align': ['center', 'right', 'justify'] }],
          [ 'link', 'image' ],
          [ 'clean' ]
    ];
    var quill = new Quill(this.editorTarget, {
      modules: { toolbar: toolbar },
      theme: 'snow',
    });
    quill.container.firstChild.innerHTML = this.inputTarget.value;
    quill.on('text-change', (delta, oldDelta, source) => {
      this.inputTarget.value = quill.container.firstChild.innerHTML;
    });
  }

  /* ----- FUNCTIONS ----- */
}

