import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['actionInput', 'selectionInput', 'allInput']

  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  onActionClick(event) {
    event.preventDefault()

    if (!event.currentTarget.dataset.confirm) {
      this.setActionInputValue(event.currentTarget.dataset.batchActionType)
      this.submitForm()
    }
  }

  /* ----- FUNCTIONS ----- */

  setActionInputValue(value) {
    this.actionInputTarget.value = value
  }

  setSelectionInputValue(value) {
    this.selectionInputTarget.value = value
  }

  setAllInputValue(value) {
    this.allInputTarget.value = value
  }

  submitForm() {
    this.element.submit()
  }

  /* ----- GETTERS / SETTERS ----- */
}
