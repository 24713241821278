import { Controller } from 'stimulus'
import { get } from '../../utils/ajax'

export default class extends Controller {
  static targets = ['source']

  connect() {
    this.initTrees()
  }

  /* ----- ACTION EVENTS ----- */

  onChangeSearchDate(event) {
    event.currentTarget.closest('form').submit()
  }

  initTrees() {
    if(this.hasSourceTarget && !this.sourceTarget.querySelector('.orgchart')) {
      get(this.sourceTarget.dataset.source, 'json').then((data) => {
        data.js_sponsored_trees.forEach((tree, index) => {
          $('.orgchart' + (index + 1)).orgchart({
            'data': tree,
            'nodeTemplate': this.nodeTemplate,
            'initCompleted': function (chart) {
              chart.scrollLeft((chart[0].scrollWidth - chart.width()) / 2)
              chart.addClass('noncollapsable')
              $('.orgchart' + (index + 1) + ' .icon').remove()
              chart.closest('.row').find('.sponsor').removeClass('d-none')
            }
          })
        })
      })
    }
  }

  nodeTemplate(data) {
    let string = `
      <a href="${data.url}">
        <div class="flowchart-item">
          <div class="flowchart-content">
            <div class="image-container">
              `
                if (data.image == '<i class="fas fa-user"></i>') {
                  string += `<div class="default-image"></div>`
                } else {
                  string += `${data.image}`
                }
              string += `
            </div>
            <div class="text-container">
              `
                if (data.type == 'inherit') {
                  string += `<span class="tag tag--upper">RL</span>`
                }
                else {
                  if (data.buybacked == true) {
                    string += `<span class="tag tag--upper">RA</span>`
                  }
                  else if (data.recruited == 'cp') {
                    string += `<span class="tag tag--upper">CP</span>`
                  }
                  else if (data.recruited == 'rd') {
                    string += `<span class="tag tag--upper">RD</span>`
                  }
                }
              string += `
              <p class="date">${data.arrived_on}</p>
              <p class="name">${data.fullname}</p>
              <div class="tag-container">
              `
                if (data.agent_level) {
                  string += `<span class="tag">${data.agent_level}</span>`
                }

                string += `
                <span class="tag">${data.contract_level}</span>
              </div>
              <div class="tag-container">
                `
                if (data.production_revenues) {
                  string += `
                  <div class="tag">
                    <span class="number">${data.production_revenues}</span>
                  </div>
                  `
                }
              if (data.intervention_count > 1) {
                string += `
                <div class="tag">
                  <span class="number">${data.intervention_count} ventes</span>
                </div>
                `
              } else if (data.intervention_count == 1) {
                string += `
                <div class="tag">
                  <span class="number">1 vente</span>
                </div>
                `
              } else {
                string += `
                <div class="tag">
                  <span class="number">0 vente</span>
                </div>
                `
              }
              string += `
              </div>
            </div>
          </div>
        </div>
      </a>
    `

    return string
  }
}
