import { Controller } from 'stimulus'
import { useControllerMixin } from 'scripts/mixins/controller'
import { get } from 'scripts/utils/ajax'

export default class extends Controller {
  static targets = ['recruitmentType', 'arrivedOn', 'recruiter', 'blAgentsRecruiter', 'sponsorBlock',
    'sponsor', 'contractualisationFields', 'contractualisationLevelFields', 'contractVersion', 'contractVersionCommissionLevel', 'sponsorshipBegunOn', 'legacyCommissionLevelRevenuesAmount',
    'legacyValidatedOn', 'validatedOn'
  ]

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    useControllerMixin(this)
  }

  connect() {
    this.toggleShowRecruiterFields(this.recruitmentTypeTarget)
    this.toggleShowContractualisationFields(this.arrivedOnTarget)
    this.toggleShowLegacyFields(this.arrivedOnTarget)
  }

  /* ----- ACTION EVENTS ----- */

  onRecruitmentTypeChange(event) {
    this.toggleShowRecruiterFields(event.currentTarget)
  }

  onRecruiterSelect(event) {
    this.selectDefaultSponsor(event.currentTarget)
  }

  onArrivedOnChange(event) {
    if (this.arrivedOn != event.currentTarget.value) {
      // let source = new URL(this.recruiterTarget.dataset.source)
      // source.searchParams.set('q[arrival_arrived_on_lteq]', event.currentTarget.value)
      // this.recruiterTarget.dataset.source = source.toString()

      // $(this.recruiterTarget).select2('destroy')
      // this.getController('inputs--select2').setup()

      this.toggleShowContractualisationFields(event.currentTarget)
      this.updateContractVersion(event.currentTarget)
    }

    this.arrivedOn = event.currentTarget.value
    this.sponsorshipBegunOnTarget.setAttribute('value', event.currentTarget.value)
  }

  onLegacyCommissionLevelRevenuesAmountChange(event) {
    this.toggleShowLegacyFields(event.currentTarget)
    if (this.legacyCommissionLevelRevenuesAmountTarget.value == undefined || this.legacyCommissionLevelRevenuesAmountTarget.value == '') {
      this.contractualisationLevelFieldsTarget.classList.add('show')
    } else {
      this.contractualisationLevelFieldsTarget.classList.add('hide')
    }
  }

  onContractVersionChange(event) {
    if (event.currentTarget.value == '') {
      this.contractVersionCommissionLevelTarget.innerHTML = ''
      return
    }

    const source = event.currentTarget.dataset.indexSource + '/' + event.currentTarget.value

    this.sponsorBlockTarget.classList.remove('hide')
    if ($(event.currentTarget).find(':selected')[0].dataset.type == 'non_mlm') {
      this.sponsorBlockTarget.classList.add('hide')
    }

    get(source, 'json').then((contractVersion) => {
      this.contractVersionCommissionLevelTarget.innerHTML = ''

      contractVersion.commission_levels.forEach((level) => {
        let option = document.createElement('option')
        option.setAttribute('value', level.id)
        option.innerHTML = level.text
        this.contractVersionCommissionLevelTarget.appendChild(option)
      })
    })
  }

  /* ----- FUNCTIONS ----- */
  toggleShowRecruiterFields(recruitment_type) {
    const checkedRecruitmentType = recruitment_type.closest('.form-group').querySelector('input[type="radio"]:checked')

    if (checkedRecruitmentType) {
      if(checkedRecruitmentType.value == 'bl_agents') {
        this.blAgentsRecruiterTarget.classList.add('hide')
      }
      else {
        this.blAgentsRecruiterTarget.classList.remove('hide')
      }
    }
    else {
      this.blAgentsRecruiterTarget.classList.add('hide')
    }
  }

  selectDefaultSponsor(recruiter) {
    if (this.hasSponsorTarget) {
      const option = $(recruiter).find(':selected')[0].cloneNode(true)
      option.selected = true
      this.sponsorTarget.appendChild(option)
    }
  }

  toggleShowContractualisationFields(arrivedOn) {
    if (this.hasContractualisationFieldsTarget) {
      if (arrivedOn.value == undefined || arrivedOn.value == '') {
        this.contractualisationFieldsTarget.classList.add('hide')
      } else {
        this.contractualisationFieldsTarget.classList.remove('hide')
      }

      if (this.legacyCommissionLevelRevenuesAmountTarget.value == undefined || this.legacyCommissionLevelRevenuesAmountTarget.value == '') {
        this.contractualisationLevelFieldsTarget.classList.add('show')
      } else {
        this.contractualisationLevelFieldsTarget.classList.add('hide')
      }
    }
  }

  toggleShowLegacyFields(legacyCommissionLevelRevenuesAmount) {
    if (this.hasLegacyValidatedOnTarget) {
      if (legacyCommissionLevelRevenuesAmount.value == undefined || legacyCommissionLevelRevenuesAmount.value == '') {
        this.legacyValidatedOnTarget.classList.add('hide')
        const pickerControllerElement = this.validatedOnTarget.closest('[data-controller="inputs--datetime-picker"]')
        const pickerController = this.getControllerForElement(pickerControllerElement, 'inputs--datetime-picker')
        if (pickerController) {
          pickerController.picker.setDate(false, false)
        }
      } else {
        this.legacyValidatedOnTarget.classList.remove('hide')
      }
    }
  }

  updateContractVersion(arrivedOn) {
    if (this.hasContractVersionTarget) {
      let source = new URL(this.contractVersionTarget.dataset.autocompleteSource)
      source.searchParams.set('last_contract_version_of_contract_available_on', arrivedOn.value)

      get(source, 'json').then((contractVersions) => {
        this.contractVersionTarget.innerHTML = ''

        contractVersions.forEach((contractVersion) => {
          let option = document.createElement('option')
          option.setAttribute('value', contractVersion.id)
          option.setAttribute('data-type', contractVersion.type)
          option.innerHTML = contractVersion.text
          this.contractVersionTarget.appendChild(option)
        })

        const event = new Event('change')
        this.contractVersionTarget.dispatchEvent(event)
      })
    }
  }
}
