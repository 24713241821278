import { Controller } from 'stimulus'
import { useControllerMixin } from 'scripts/mixins/controller'

export default class extends Controller {
  static targets = ['sponsor']

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    useControllerMixin(this)
  }

  /* ----- ACTION EVENTS ----- */

  onBegunOnChange(event) {
    // const begunOn = event.currentTarget.value

    // let source = new URL(this.sponsorTarget.dataset.source)
    // source.searchParams.set('q[arrival_arrived_on_lteq]', begunOn)
    // this.sponsorTarget.dataset.source = source.toString()

    // $(this.sponsorTarget).select2('destroy')
    // this.getController('inputs--select2').setup()
  }

  /* ----- FUNCTIONS ----- */

  /* ----- GETTERS / SETTERS ----- */
}
