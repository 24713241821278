import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['type', 'externalFields', 'agentFields']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.toggleShowTypeFields(this.typeTarget)
  }

  /* ----- ACTION EVENTS ----- */

  onTypeChange(event) {
    this.toggleShowTypeFields(event.currentTarget)
  }

  /* ----- FUNCTIONS ----- */
  toggleShowTypeFields(type) {
    const checkedType = type.closest('.form-group').querySelector('input[type="radio"]:checked')

    if (checkedType) {
      if(checkedType.value == 'external') {
        this.externalFieldsTarget.classList.remove('hide')
        this.agentFieldsTarget.classList.add('hide')
      } else {
        this.externalFieldsTarget.classList.add('hide')
        this.agentFieldsTarget.classList.remove('hide')
      }
    } else {
      this.externalFieldsTarget.classList.add('hide')
      this.agentFieldsTarget.classList.add('hide')
    }
  }

  /* ----- GETTERS / SETTERS ----- */
}
