import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['broadcastType', 'onSiteFields', 'webinarFields']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.toggleShowBroadcastTypeFields(this.broadcastTypeTarget)
  }

  /* ----- ACTION EVENTS ----- */

  onBroadcastTypeChange(event) {
    this.toggleShowBroadcastTypeFields(event.currentTarget)
  }

  /* ----- FUNCTIONS ----- */
  toggleShowBroadcastTypeFields(type) {
    const checkedType = type.closest('.form-group').querySelector('input[type="radio"]:checked')

    if (checkedType) {
      if(checkedType.value == 'webinar') {
        this.webinarFieldsTarget.classList.remove('hide')
        this.onSiteFieldsTarget.classList.add('hide')
      } else {
        this.webinarFieldsTarget.classList.add('hide')
        this.onSiteFieldsTarget.classList.remove('hide')
      }
    } else {
      this.onSiteFieldsTarget.classList.add('hide')
      this.webinarFieldsTarget.classList.add('hide')
    }
  }

  /* ----- GETTERS / SETTERS ----- */
}
