import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['reminderBeforeExpirationDate', 'hasExpirationDate']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.toggleShowReminder(this.hasExpirationDateTarget)
  }

  /* ----- ACTION EVENTS ----- */

  onHasExpirationDateChange(event) {
    this.toggleShowReminder(event.currentTarget)
  }

  /* ----- FUNCTIONS ----- */
  toggleShowReminder(hasExpirationDate) {
    if(hasExpirationDate.checked) {
      this.reminderBeforeExpirationDateTarget.classList.remove('hide')
    } else {
      this.reminderBeforeExpirationDateTarget.classList.add('hide')
    }
  }

  /* ----- GETTERS / SETTERS ----- */
}
