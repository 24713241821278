import { Controller } from 'stimulus'
import { useControllerMixin } from 'scripts/mixins/controller'

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    useControllerMixin(this)
  }

  /* ----- ACTION EVENTS ----- */

  onAjaxSuccess(event) {
    const [response, status, xhr] = event.detail
    const responseOption = response.body.querySelector('option')
    const inputReferrer = document.querySelector(`select[data-creation-referrer="${event.currentTarget.dataset.creationReferrer}"]`)

    responseOption.selected = true
    inputReferrer.append(responseOption)
    inputReferrer.dispatchEvent(new Event('change'))

    this.getController('modal').hide()
  }

  /* ----- FUNCTIONS ----- */

  /* ----- GETTERS / SETTERS ----- */
}
